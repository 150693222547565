import React, { useState } from 'react';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });

  // Sanitize input while preserving legitimate spaces
  const sanitizeInput = (input) => {
    return input.replace(/[<>]/g, '');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: sanitizeInput(value)
    }));
  };

  // We're removing the custom handleSubmit function and letting Netlify handle it directly

  return (
    // The netlify attribute tells Netlify to handle this form
    <form 
      name="contact" 
      method="POST" 
      netlify="true"
      className="contact-form"
    >
      {/* This hidden input is essential for Netlify forms with React */}
      <input type="hidden" name="form-name" value="contact" />
      
      <div className="form-group">
        <label htmlFor="name">Name *</label>
        <input 
          type="text" 
          id="name" 
          name="name" 
          value={formData.name}
          onChange={handleChange}
          maxLength={100}
          required 
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="email">Email *</label>
        <input 
          type="email" 
          id="email" 
          name="email" 
          value={formData.email}
          onChange={handleChange}
          maxLength={100}
          required 
        />
      </div>

      <div className="form-group">
        <label htmlFor="company">Company</label>
        <input 
          type="text" 
          id="company" 
          name="company" 
          value={formData.company}
          onChange={handleChange}
          maxLength={100}
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="message">How Can I Help? *</label>
        <textarea 
          id="message" 
          name="message" 
          value={formData.message}
          onChange={handleChange}
          rows="4" 
          maxLength={1000}
          required
        />
      </div>
      
      <button type="submit" className="button">Send Message</button>
    </form>
  );
}

export default ContactForm;